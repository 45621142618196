import './Chat.css';

function Chat() {
    return (
        <div className='ChatContainer '>
            <div className='ChatSubContainerLeft'>
            <div className='ChatTextContainer'>
                    <p className='ChatIntroductionText'>Chatta med upphandlingsdokumenten</p>
                    <p className='ChatHeaderText'>Gör det <span style={{color: '#0075FF'}}>enkelt</span> att förstå upphandlingen</p>
                    <p className='ChatDescriptionText'>Hitta precis den information du söker - oavsett om det gäller upphandlingsdokument eller er interna kunskapsbank. Vår AI söker snabbt fram relevanta svar, källhänvisar till rätt dokument och markerar exakt var informationen finns. På så sätt kan du känna dig trygg med att få korrekta och pålitliga svar på alla dina frågor. </p>
                </div>
            </div>
            <div className='ChatSubContainerRight'>
                
                <img src={`${process.env.PUBLIC_URL}/chat.svg`} alt='placeholder' className='ChatImage' />
   
            </div>

        </div>
    )
}


export default Chat;