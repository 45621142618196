import './Footer.css';
import ShortcutButton from '../../components/ShortcutButton/ShortcutButton';
import { useDisclosure } from '@mantine/hooks';
import ModalComponent from '../../components/ModalComponent/ModalComponent';

function Footer() {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <div className='FooterRoot'>
            <div className='FooterContainer'>
                <ModalComponent opened={opened} close={close} />
                <div className='FooterSubContainer'>
                    <div>
                        <p className='FooterIntroduction'>Låt Hedda hjälpa</p>
                        <p className='FooterText'><span style={{ color: '#0075FF' }}>Effektivisera</span> ert bid-team</p>
                    </div>
                    <p className='FooterSecondaryText'>Hedda AB</p>

                </div>
                <div className='FooterSubContainerLeft'>
                    <div className='FooterRightButtonAndText'>
                        <p className='FooterPromoText'>Låt oss visa hur enkelt anbudsskrivande kan bli</p>
                        <div className='FooterButtonContainer'>
                            <ShortcutButton text='Boka demo' color='white' onClick={open} />
                        </div>
                    </div>
                    <p className='FooterSecondaryText'>Hedda © 2024 All rights reserved.</p>

                </div>
            </div>


        </div>
    )
}

export default Footer;